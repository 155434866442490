<template>
    <topBanner/>
    <services/>
</template>

<script>
import topBanner from '../components/common/topBanner.vue'
import services from '../components/services.vue'
export default {
    components:{
        topBanner,
        services
    },
    data(){
        return{}
    }
}
</script>