<template>
  <section
    class="cmt-row services2-section cmt-bgcolor-grey mt_80 res-991-mt-0 clearfix"
  >
    <div class="container">
      <!-- row -->
      <div class="row align-items-center">
        <div class="col-xl-9 col-lg-9 col-md-12">
          <!-- section title -->
          <div class="section-title style2">
            <div class="title-header">
              <!-- <h5>YOU CAN LEARN IT</h5> -->
              <h2 class="title">Our Services</h2>
            </div>
            <div class="title-desc">
              At our AutoService garage, we fully appreciaor people to find
              reliable and trustworthy garages where theye and repair
            </div>
          </div>
          <!-- section title end -->
        </div>
        <div class="col-xl-3 col-lg-3 col-md-12">
          <a
            class="cmt-btn cmt-btn-size-md cmt-btn-shape-square cmt-btn-style-border cmt-btn-color-dark mb-30 float-lg-right"
            href="#"
            >Read More</a
          >
        </div>
      </div>
      <!-- row end -->
    </div>
    <div class="container">
      <div class="row">
        <div class="cmt-box-col-wrapper col-lg-3">
          <!-- featured-imagebox-course -->
          <div class="featured-imagebox featured-imagebox-services style2">
            <div class="featured-content featured-content-services">
              <div class="featured-title">
                <h5><a href="course-single.html">Service 1</a></h5>
              </div>
              <div class="featured-desc">
                <p>
                  Lorem Ipsum is simply dummy text of the printing and
                  typesetting industry.
                </p>
              </div>
              <div class="fea-btn">
                <a href="#"><i class="ti ti-arrow-right"></i></a>
              </div>
            </div>
            <div class="featured-thumbnail">
              <img
                class="img-fluid"
                src="../assets/images/services/shiprepairs/1.jpg"
                alt="image"
              />
            </div>
          </div>
          <!-- featured-imagebox-course end-->
        </div>

        <div class="cmt-box-col-wrapper col-lg-3">
          <!-- featured-imagebox-course -->
          <div class="featured-imagebox featured-imagebox-services style2">
            <div class="featured-content featured-content-services">
              <div class="featured-title">
                <h5><a href="course-single.html">Service 2</a></h5>
              </div>
              <div class="featured-desc">
                <p>
                  Lorem Ipsum is simply dummy text of the printing and
                  typesetting industry.
                </p>
              </div>
              <div class="fea-btn">
                <a href="#"><i class="ti ti-arrow-right"></i></a>
              </div>
            </div>
            <div class="featured-thumbnail">
              <img
                class="img-fluid"
                src="../assets/images/services/shiprepairs/1.jpg"
                alt="image"
              />
            </div>
          </div>
          <!-- featured-imagebox-course end-->
        </div>
        <div class="cmt-box-col-wrapper col-lg-3">
          <!-- featured-imagebox-course -->
          <div class="featured-imagebox featured-imagebox-services style2">
            <div class="featured-content featured-content-services">
              <div class="featured-title">
                <h5><a href="course-single.html">Service 3</a></h5>
              </div>
              <div class="featured-desc">
                <p>
                  Lorem Ipsum is simply dummy text of the printing and
                  typesetting industry.
                </p>
              </div>
              <div class="fea-btn">
                <a href="#"><i class="ti ti-arrow-right"></i></a>
              </div>
            </div>
            <div class="featured-thumbnail">
              <img
                class="img-fluid"
                src="../assets/images/services/shiprepairs/1.jpg"
                alt="image"
              />
            </div>
          </div>
          <!-- featured-imagebox-course end-->
        </div>
        <div class="cmt-box-col-wrapper col-lg-3">
          <!-- featured-imagebox-course -->
          <div class="featured-imagebox featured-imagebox-services style2">
            <div class="featured-content featured-content-services">
              <div class="featured-title">
                <h5><a href="course-single.html">Service 4</a></h5>
              </div>
              <div class="featured-desc">
                <p>
                  Lorem Ipsum is simply dummy text of the printing and
                  typesetting industry.
                </p>
              </div>
              <div class="fea-btn">
                <a href="#"><i class="ti ti-arrow-right"></i></a>
              </div>
            </div>
            <div class="featured-thumbnail">
              <img
                class="img-fluid"
                src="../assets/images/services/shiprepairs/1.jpg"
                alt="image"
              />
            </div>
          </div>
          <!-- featured-imagebox-course end-->
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  data() {
    return {};
  },
};
</script>
